<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <label>Range Type</label>
        <select v-model="rangeData" class="form-control" name="created_by" data-live-search="true" placeholder="Range">
          <option value="customData" selected>Custom</option>
          <option value="monthlyData">Monthly</option>
          <option value="yearlyData">Yearly</option>
        </select>
      </div>
      <div class="col">
        <label>Status</label>
        <select v-model="jobfileTypeData" class="form-control" name="jobfileType" data-live-search="true"
          placeholder="Jobfile Type">
          <option value="All" selected>All</option>
          <option value="1">Export</option>
          <option value="2">Import</option>
          <option value="3">Domestic</option>
        </select>
      </div>
      <div v-if="rangeData === 'customData'" class="col justify-content-end">
        <label>Start Date</label>
        <!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
        <date-picker v-model="startDateData" :format="momentFormatStartDateData" type="date" lang="en"
          :name="'startDate'" :placeholder="'Start Date'">
        </date-picker>
      </div>
      <div v-if="rangeData === 'customData'" class="col justify-content-end">
        <label>End Date</label>
        <!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
        <date-picker v-model="endDateData" :format="momentFormatEndDateData" type="date" lang="en" :name="'endDate'"
          :placeholder="'End Date'" @input="fecthData">
        </date-picker>
      </div>
      <div v-if="rangeData === 'monthlyData'" class="col justify-content-end">
        <label>Month</label>
        <select v-model="monthData" v-can="'CRM_ADMIN_ENABLE'" class="form-control" name="created_by"
          data-live-search="true" placeholder="Range" @change="fecthData">
          <option value="01">January</option>
          <option value="02">February</option>
          <option value="03">March</option>
          <option value="04">April</option>
          <option value="05">May</option>
          <option value="06">June</option>
          <option value="07">July</option>
          <option value="08">August</option>
          <option value="09">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
      </div>
      <div v-if="rangeData === 'monthlyData' || rangeData === 'yearlyData'">
        <label>Year</label>
        <!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
        <date-picker v-model="yearData" type="year" :format="formatYearData" :name="'yearData'" :placeholder="'Year'"
          @input="fecthData">
        </date-picker>
      </div>

      <div class="col-md-2">
        <label for=""></label>
        <a href="#" class="btn btn-block btn-success mt-1 ">Filter</a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3" style="align-content: center">
        <label for=""></label>
        <a href="#" class="btn btn-success " @click="openModalCreated(null)">
          <i class="fas fa-plus"></i> Create Main Job</a>
      </div>
      <div class="col-md-6"> </div>
      <div class="col-md-3" style="display: flex; justify-content: end">
        <label class="mb-2 mr-sm-2"></label>
        <input v-model="filterText" type="text" class="form-control form-control mb-2" placeholder="Search . . ."
          @keyup="doFilter" />
      </div>
    </div>
    <!-- <h4 class="header-title">List Quotation</h4> -->
    <vue-element-loading :active="isLoadingTable" spinner="bar-fade-scale" color="#FF6700" />

    <!-- Table -->
    <div class="mt-2">
      <vuetable ref="vuetable" :per-page="perPage" :fields="fields" :api-url="apiUrl" :api-mode="true"
        :http-options="httpOptions" :append-params="moreParams" pagination-path
        class="table table-centered table-striped" data-path="data" detail-row-component="my-detail-row"
        @vuetable:loading="loadingTable" @vuetable:load-success="loadTableSuccess"
        @vuetable:load-error="loadTableFailed" @vuetable:pagination-data="onPaginationData"
        @vuetable:cell-clicked="onCellClicked" :sort-order="sortOrder">
        <div slot="no" slot-scope="props" v-if="props.rowData.child_job_orders_count > 0">
          <a href="#" class="" style="color: #ff8d00" @click="onCellClicked(props.rowData, props.rowData)">
            <i class="fas fa-angle-down"></i></a>
        </div>
        <div slot="id_number" slot-scope="props">
          <a v-can="" href="javascript:void(0);" class="action-icon mr-1"
            @click="onAction('details', props.rowData, props.rowIndex)"
            style="font-size: 12px; color: blue; text-decoration: underline">
            {{ props.rowData.id_number }}
          </a>
        </div>

        <div slot="status">
          <a href="#" class="btn btn-success " size="sm"> Status Job</a>
        </div>
        <div slot="info">
          <i class="fa fa-info-circle" style="color: red; font-size: 20px" aria-hidden="true"></i>
        </div>
        <div slot="action" slot-scope="props">
          <b-dropdown id="dropdown-1" right size="sm" variant="btn-drop">
            <template v-slot:button-content>
              <i class="fa fa-caret-down ml-2" style="padding-right: 10px; color: #f9a707"></i>
            </template>
            <b-dropdown-item><i class="fa fa-edit mr-2"></i>View Timeline</b-dropdown-item>
            <b-dropdown-item><i class="fa fa-edit mr-2"></i>Edit Data Info</b-dropdown-item>
            <b-dropdown-item @click="openModalEdit(props)">
              <i class="fas fa-pen mr-2"></i>Edit Job Order
            </b-dropdown-item>
            <b-dropdown-item><i class="fa fa-edit mr-2"></i>Update Status</b-dropdown-item>
            <!-- jadi kondisinya delete akan enable saat sub job order tidak ada , saat akan muncul popup mohon hapus sub job order -->

            <b-dropdown-item v-if="props.rowData.child_job_orders_count < 1"
              @click="showConfirmDelete(props.rowData.id)"><i class="fa fa-edit mr-2"></i>Delete Job
              Order</b-dropdown-item>


            <b-dropdown-item v-if="props.rowData.child_job_orders_count > 0"
              @click="showConfirmDeleteMessage(props.rowData.id)"><i class="fa fa-edit mr-2"></i>Delete
              Job Order</b-dropdown-item>


            <b-dropdown-item>
              <a href="#" class="btn btn-success" size="sm" @click="openModalCreated(props)">
                <i class="fas fa-plus"></i> Add Multi Job</a>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </vuetable>
    </div>
    <!-- end table -->

    <!-- pagination -->
    <div class="row">
      <div class="col-md-5">
        <div class="row no-gutters">
          <div class="col-lg-3">
            <div class="form-group float-lg-left">
              <select v-model="perPage" class="form-control form-control-sm" @change="onChangeTableLength">
                <option v-for="opt in lengthMenu" :key="opt" :value="opt">
                  {{ opt }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-8">
            <vuetable-pagination-info ref="paginationInfo" class="
                justify-content-center justify-content-lg-start
                text-center text-lg-left
              ">
            </vuetable-pagination-info>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-7">
        <vuetable-pagination ref="pagination" :css="css.pagination" class="
            pagination pagination-rounded
            justify-content-center justify-content-lg-end
          " @vuetable-pagination:change-page="onChangePage">
        </vuetable-pagination>
      </div>
    </div>
    <!-- end pagination -->

    <!-- Modal Created -->
    <b-modal id="my-modal" size="lg" :title="TitleModal" hide-footer>
      <form @submit.prevent="getAllData" id="frm">
        <div class="col-4 mt-2" style="display: none;" v-if="idJobOrder !== ''">
          <label for="" style="display: none;">id job order <span style="color: red">*</span></label>
          <b-form-input style="display: none;" v-model="idJobOrder" name="id_parent" id="">
          </b-form-input>
        </div>
        <div class="row">
          <div class="col-4 mt-2">
            <label for="">Branch <span style="color: red">*</span></label>
            <v-select v-if="createdJobOrder" v-model="JobOrderCreated.Branch" :options="masterBranch" item-text="name"
              item-value="id" @change="getMarketingData" @input="getMarketingData" :reduce="(option) => option.id"
              label="name">
            </v-select>



            <b-form-select v-if="createdSubJobOrder" v-model="JobOrderEdit.Branch" :options="masterBranch" class="mb-3"
              value-field="id" text-field="name" :disabled="JobOrderCreated.Branch"></b-form-select>


          </div>
          <div class="col-4 mt-2">
            <label for="">Customer <span style="color: red">*</span></label>
            <v-select v-model="JobOrderCreated.customer" name="customer" item-text="name" item-value="id"
              :options="selectCustomer" :reduce="(option) => option.id" label="name">
            </v-select>
          </div>
          <div class="col-4 mt-2">
            <label>Sales <span style="color: red">*</span></label>
            <v-select v-model="JobOrderCreated.sales" name="sales" item-text="name" item-value="id"
              :options="selectSales" :reduce="(option) => option.id" label="name">
            </v-select>
          </div>
          <div class="col-4 mt-2">
            <b-form-group class="mb-2 created-field">
              <label>ETD <span style="color: red">*</span></label>
              <b-form-datepicker v-model="JobOrderCreated.etd" name="etd" :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }" locale="en"></b-form-datepicker>
            </b-form-group>
          </div>
          <div class="col-4 mt-2">
            <b-form-group label="ETA" class="mb-2 created-field">
              <b-form-datepicker name="eta" :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }" locale="en"></b-form-datepicker>
            </b-form-group>
          </div>
          <div class="col-4 mt-2">
            <label>No JobOrder</label>
            <div class="input-group mb-3">
              <b-form-input v-if="isJobOrderNo" v-model="autoJobOrderNo" type="text" name="id_number" id="" readonly>
              </b-form-input>
              <b-form-input v-if="isManualJobOrderNo" v-model="autoJobOrderNo" name="id_number"></b-form-input>
              <div class="input-group-append" style="background: #51c1ad; border-radius: 0px 3px 3px 0px">
                <button class="btn" type="button" @click="changeJobOrderNo">
                  <div v-if="isJobOrderNo">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_5471_8985)">
                        <path
                          d="M15.6955 2.78553L13.2098 0.299919C12.8168 -0.0931369 12.1315 -0.0931369 11.7378 0.299919L11.1367 0.90097L15.0951 4.85865L15.6955 4.25829C15.8923 4.06141 16.0002 3.79983 16.0002 3.52158C16.0001 3.2433 15.8923 2.98171 15.6955 2.78553Z"
                          fill="white" />
                        <path
                          d="M0.0135096 15.5518C-0.021271 15.6729 0.0121346 15.8037 0.101852 15.8934C0.167945 15.9595 0.256974 15.995 0.348129 15.995C0.379441 15.995 0.41144 15.9908 0.44344 15.9818L5.11627 14.6468L1.34921 10.879L0.0135096 15.5518Z"
                          fill="white" />
                        <path d="M1.74549 10.2919L5.70312 14.2495L14.6027 5.34989L10.6451 1.39225L1.74549 10.2919Z"
                          fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5471_8985">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div v-if="isManualJobOrderNo">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.64999 5.00031L9.74362 1.90668C10.0854 1.56487 10.0854 1.0107 9.74362 0.669421L9.3312 0.257001C8.98928 -0.0849194 8.43511 -0.0849194 8.09383 0.257001L5.00031 3.35052L1.90668 0.25636C1.56487 -0.0854535 1.0107 -0.0854535 0.669421 0.25636L0.25636 0.66878C-0.0854535 1.0107 -0.0854535 1.56487 0.25636 1.90615L3.35052 5.00031L0.257001 8.09383C-0.0849194 8.43575 -0.0849194 8.98992 0.257001 9.3312L0.669421 9.74362C1.01124 10.0854 1.5654 10.0854 1.90668 9.74362L5.00031 6.64999L8.09383 9.74362C8.43575 10.0854 8.98992 10.0854 9.3312 9.74362L9.74362 9.3312C10.0854 8.98928 10.0854 8.43511 9.74362 8.09383L6.64999 5.00031Z"
                        fill="#FFFFFF" />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr style="border-top: 1px solid #e6e6e6" />

        <div v-for="(item, key) in createdField" :key="key" :title="item.title">
          <div v-if="item.is_type_group === 'radio'" class="row">
            <div class="col-4 mt-2 m-t-10 d-flex justify-content-between">
              <label>{{ item.title }}
                <span v-if="item.is_required === '1'" style="color: red">*</span>
              </label>
              <span>:</span>
            </div>
            <div class="col-8 m-t-10">
              <b-form-group>
                <b-form-radio-group class="row radio-no-between" :name="item.key" v-model="form.parent_id[item.key]">
                  <div v-for="(items, keys) in item.company_setting_job_order_item" :key="keys"
                    :json="setJson(items.meta)" class="col-4 mt-2">
                    <div v-if="json.type === 'radio'">
                      <b-form-radio :value="items.key" size="lg" plain>{{
                      items.title
                      }}</b-form-radio>
                    </div>
                  </div>
                </b-form-radio-group>
              </b-form-group>
            </div>
          </div>
          <div v-else-if="item.is_type_group === 'checklist'" class="row">
            <div class="col-4 mt-2 m-t-10 d-flex justify-content-between">
              <label>{{ item.title }}
                <span v-if="item.is_required === '1'" style="color: red">*</span>
              </label>
              <span>:</span>
            </div>
            <div class="col-8 m-t-10">
              <b-form-group label="" v-slot="{ ariaDescribedby }">
                <b-form-checkbox v-for="(items, keys) in item.company_setting_job_order_item" :key="keys"
                  :value="items.key" :aria-describedby="ariaDescribedby" :name="items.key" v-model="selected.item"
                  inline class="col-4 mt-2">
                  {{ items.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div v-else-if="item.is_type_group === 'form_input'" class="row">
            <div v-for="(items, keys) in item.company_setting_job_order_item" :key="keys" :json="setJson(items.meta)"
              class="col-4">
              <div v-if="json.type === 'textarea' && json.hidden !== '1'">
                <b-form-group>
                  <label for="" class="mt-2">
                    {{ firstCapital(items.title) }}
                    <span v-if="json.required === '1'" style="color: red">*</span>
                  </label>
                  <b-form-textarea :name="items.key" :id="items.key" :placeholder="'Enter Your ' + items.title"
                    v-model="form.parent_id[items.key]"></b-form-textarea>
                </b-form-group>
              </div>
              <div v-else-if="json.type === 'text'">
                <b-form-group>
                  <label for="" class="mt-2">
                    {{ firstCapital(items.title) }}
                    <span v-if="json.required === '1'" style="color: red">*</span>
                  </label>

                  <b-form-input :id="items.key" :placeholder="'Enter Your ' + items.title" :name="items.key"
                    v-model="form.parent_id[items.key]"></b-form-input>
                </b-form-group>
              </div>
              <div v-else-if="json.type === 'date'">
                <b-form-group class="created-field">
                  <label for="" class="mt-2">
                    {{ firstCapital(items.title) }}
                    <span v-if="json.required === '1'" style="color: red">*</span>
                  </label>

                  <b-form-datepicker :name="items.key" :id="items.key" v-model="form.parent_id[items.key]"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }" locale="en"></b-form-datepicker>
                </b-form-group>
              </div>
              <div v-else-if="json.type === 'dropdown'">
                <label class="mt-2">
                  {{ firstCapital(items.title) }}
                  <span v-if="json.is_required === '1'" style="color: red">*</span>
                </label>
                <v-select :options="json.options_data" item-text="name" item-value="id"
                  v-model="form.parent_id[items.key]" :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'number'">
                <b-form-group>
                  <label for="" class="mt-2">
                    {{ firstCapital(items.title) }}
                    <span v-if="json.required === '1'" style="color: red">*</span>
                  </label>
                  <b-form-input :id="items.key" type="number" :name="items.key" v-model="form.parent_id[items.key]"
                    :placeholder="'Enter Your ' + items.title"></b-form-input>
                </b-form-group>
              </div>
              <div v-else-if="json.type === 'select_master_agent'">
                <label class="mt-2">{{ firstCapital(items.title) }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>

                <v-select :options="masterAgent" item-text="name" item-value="id" v-model="form.parent_id[items.key]"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_airlanes'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterAirlanes" item-text="name" item-value="id" v-model="form.parent_id[items.key]"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_currency'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterCurrency" item-text="name" item-value="id" v-model="form.parent_id[items.key]"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_depo'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterDepo" item-text="name" item-value="id" v-model="form.parent_id[items.key]"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_driver'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterDriver" item-text="name" item-value="id" v-model="form.parent_id[items.key]"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_item'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterItem" item-text="name" item-value="id" v-model="form.parent_id[items.key]"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_port'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterPort" item-text="name" item-value="id" v-model="form.parent_id[items.key]"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_reason'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterReason" item-text="name" item-value="id" v-model="form.parent_id[items.key]"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_shipping'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterShipping" item-text="name" item-value="id" v-model="form.parent_id[items.key]"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_term'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterTerm" item-text="name" item-value="id" v-model="form.parent_id[items.key]"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_truck'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterTruck" item-text="name" item-value="id" v-model="form.parent_id[items.key]"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_address'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterAddress" item-text="name" item-value="id" v-model="form.parent_id[items.key]"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_authorized'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterAuthorized" item-text="name" item-value="id"
                  v-model="form.parent_id[items.key]" :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_assignment'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterAssignment" item-text="name" item-value="id"
                  v-model="form.parent_id[items.key]" :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_ppjk'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterPPJK" item-text="name" item-value="id" v-model="form.parent_id[items.key]"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_tax'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterTax" item-text="name" item-value="id" v-model="form.parent_id[items.key]"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_vendor'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterVendor" item-text="name" item-value="id" v-model="form.parent_id[items.key]"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
            </div>
          </div>
          <hr style="border-top: 1px solid #e6e6e6" />
        </div>
        <div slot="modal-footer" class="w-100">
          <p class="float-left"></p>
          <input type="submit" class="btn btn-primary mr-1 float-right" value="Save" />
          <input @click="hideModalCreated" type="button" class="btn btn-secondary mr-1 float-right" value="Close" />
        </div>
      </form>
    </b-modal>
    <!-- end Modal Created -->

    <!-- Modal Edit -->
    <b-modal id="my-modal-edit" size="lg" title="Edit Job Order" hide-footer>
      <form @submit.prevent="getUpdate" id="frm-edit">
        <div class="row">
          <div class="col-4 mt-2">
            <label for="">Branch <span style="color: red">*</span></label>
            <v-select v-model="JobOrderEdit.Branch" :options="masterBranch" item-text="name" item-value="id"
              @input="getMarketingDataEdit" :reduce="(option) => option.id" label="name">
            </v-select>
            <!-- <b-form-select v-model="JobOrderEdit.Branch" :options="masterBranch" class="mb-3" value-field="id"
              text-field="name" :disabled="JobOrderCreated.Branch"></b-form-select> -->
          </div>
          <div class="col-4 mt-2">
            <label for="">Customer <span style="color: red">*</span></label>
            <v-select v-model="JobOrderEdit.customer" name="customer" item-text="name" item-value="id"
              :options="selectCustomer" :reduce="(option) => option.id" label="name">
            </v-select>
          </div>
          <div class="col-4 mt-2">
            <label>Sales <span style="color: red">*</span></label>
            <v-select v-model="JobOrderEdit.sales" name="sales" item-text="name" item-value="id" :options="selectSales"
              :reduce="(option) => option.id" label="name">
            </v-select>
          </div>
          <!-- <b-form-input

          ></b-form-input> -->
          <input type="hidden" name="id" v-model="idJobOrder" placeholder="Enter your name" />
          <div class="col-4 mt-2">
            <b-form-group class="mb-2 created-field">
              <label for="">ETD <span style="color: red">*</span></label>
              <b-form-datepicker v-model="etd" name="etd" :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }" locale="en"></b-form-datepicker>
            </b-form-group>
          </div>
          <div class="col-4 mt-2">
            <b-form-group label="ETA" class="mb-2 created-field">
              <b-form-datepicker v-model="eta" name="eta" :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }" locale="en"></b-form-datepicker>
            </b-form-group>
          </div>
          <div class="col-4 mt-2">
            <label>No JobOrder</label>
            <div class="input-group mb-3">
              <!-- "autoJobOrderNo" -->
              <b-form-input v-if="isJobOrderNo" v-model="autoJobOrderNo" type="text" name="id_number" id="" readonly>
              </b-form-input>
              <b-form-input v-if="isManualJobOrderNo" v-model="autoJobOrderNo" name="id_number"></b-form-input>
              <div class="input-group-append" style="background: #51c1ad; border-radius: 0px 3px 3px 0px">
                <button class="btn" type="button" @click="changeJobOrderNo">
                  <div v-if="isJobOrderNo">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_5471_8985)">
                        <path
                          d="M15.6955 2.78553L13.2098 0.299919C12.8168 -0.0931369 12.1315 -0.0931369 11.7378 0.299919L11.1367 0.90097L15.0951 4.85865L15.6955 4.25829C15.8923 4.06141 16.0002 3.79983 16.0002 3.52158C16.0001 3.2433 15.8923 2.98171 15.6955 2.78553Z"
                          fill="white" />
                        <path
                          d="M0.0135096 15.5518C-0.021271 15.6729 0.0121346 15.8037 0.101852 15.8934C0.167945 15.9595 0.256974 15.995 0.348129 15.995C0.379441 15.995 0.41144 15.9908 0.44344 15.9818L5.11627 14.6468L1.34921 10.879L0.0135096 15.5518Z"
                          fill="white" />
                        <path d="M1.74549 10.2919L5.70312 14.2495L14.6027 5.34989L10.6451 1.39225L1.74549 10.2919Z"
                          fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5471_8985">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div v-if="isManualJobOrderNo">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.64999 5.00031L9.74362 1.90668C10.0854 1.56487 10.0854 1.0107 9.74362 0.669421L9.3312 0.257001C8.98928 -0.0849194 8.43511 -0.0849194 8.09383 0.257001L5.00031 3.35052L1.90668 0.25636C1.56487 -0.0854535 1.0107 -0.0854535 0.669421 0.25636L0.25636 0.66878C-0.0854535 1.0107 -0.0854535 1.56487 0.25636 1.90615L3.35052 5.00031L0.257001 8.09383C-0.0849194 8.43575 -0.0849194 8.98992 0.257001 9.3312L0.669421 9.74362C1.01124 10.0854 1.5654 10.0854 1.90668 9.74362L5.00031 6.64999L8.09383 9.74362C8.43575 10.0854 8.98992 10.0854 9.3312 9.74362L9.74362 9.3312C10.0854 8.98928 10.0854 8.43511 9.74362 8.09383L6.64999 5.00031Z"
                        fill="#FFFFFF" />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr style="border-top: 1px solid #e6e6e6" />

        <div v-for="(item, key) in createdField" :key="key" :title="item.title">
          <div v-if="item.is_type_group === 'radio'" class="row">
            <div class="col-4 mt-2 m-t-10 d-flex justify-content-between">
              <label>{{ item.title }}
                <span v-if="item.is_required === '1'" style="color: red">*</span>
              </label>
              <span>:</span>
            </div>
            <div class="col-8 m-t-10">
              <b-form-group>
                <b-form-radio-group class="row radio-no-between" :name="item.key" v-model="radioSelected">
                  <div v-for="(items, keys) in item.company_setting_job_order_item" :key="keys"
                    :json="setJson(items.meta)" class="col-4 mt-2">
                    <div v-if="json.type === 'radio'">
                      <b-form-radio :value="items.key" size="lg" plain>{{
                      items.title
                      }}</b-form-radio>
                    </div>
                  </div>
                </b-form-radio-group>
              </b-form-group>
            </div>
          </div>
          <div v-else-if="item.is_type_group === 'checklist'" class="row">
            <div class="col-4 m-t-10 d-flex mt-2 justify-content-between">
              <label>{{ item.title }}
                <span v-if="item.is_required === '1'" style="color: red">*</span>
              </label>
              <span>:</span>
            </div>
            <div class="col-8 m-t-10">
              <b-form-group>
                <b-form-checkbox-group class="row d-flex justify-content-between"
                  v-for="(items, keys) in item.company_setting_job_order_item" :key="keys" :json="setJson(items.meta)"
                  :name="items.key" v-model="checkboxSelected">
                  <div v-if="json.type === 'checkbox'" class="col-4 mt-2">
                    <b-form-checkbox :id="items.key" :value="items.key" unchecked-value="0" inline>{{ items.title }}
                    </b-form-checkbox>
                  </div>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
          <div v-else-if="item.is_type_group === 'form_input'" class="row">
            <div v-for="(items, keys) in item.company_setting_job_order_item" :key="keys" :json="setJson(items.meta)"
              class="col-4">
              <div v-if="json.type === 'textarea' && json.hidden !== '1'">
                <b-form-group>
                  <label class="mt-2">
                    {{ firstCapital(items.title) }}
                    <span v-if="item.is_required === '1'" style="color: red">*</span>
                  </label>
                  <b-form-textarea :name="items.key" :id="items.key" :placeholder="'Enter Your ' + items.title"
                    v-model="formEdit.parent_id[items.key]"></b-form-textarea>
                </b-form-group>
              </div>
              <div v-else-if="json.type === 'text'">
                <b-form-group>
                  <label class="mt-2">
                    {{ firstCapital(items.title) }}
                    <span v-if="item.is_required === '1'" style="color: red">*</span>
                  </label>
                  <b-form-input :id="items.key" :placeholder="'Enter Your ' + items.title" :name="items.key"
                    v-model="formEdit.parent_id[items.key]"></b-form-input>
                </b-form-group>
              </div>
              <div v-else-if="json.type === 'date'">
                <b-form-group class="created-field">
                  <label for="" class="mt-2">
                    {{ firstCapital(items.title) }}

                    <span v-if="item.is_required === '1'" style="color: red">*</span>
                  </label>

                  <b-form-datepicker :name="items.key" :id="items.key" v-model="formEdit.parent_id[items.key]"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }" locale="en"></b-form-datepicker>
                </b-form-group>
              </div>
              <div v-else-if="json.type === 'dropdown'">
                <label class="mt-2">
                  {{ firstCapital(items.title) }}
                  <span v-if="item.is_required === '1'" style="color: red">*</span>
                </label>
                <v-select :options="json.options_data" item-text="name" item-value="id"
                  v-model="formEdit.parent_id[items.key]" @input="changeSelected(items.key)"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'number'">
                <b-form-group>
                  <label class="mt-2">
                    {{ firstCapital(items.title) }}
                    <span v-if="item.is_required === '1'" style="color: red">*</span>
                  </label>
                  <b-form-input :id="items.key" type="number" :name="items.key"
                    :placeholder="'Enter Your ' + items.title" v-model="formEdit.parent_id[items.key]"></b-form-input>
                </b-form-group>
              </div>
              <div v-else-if="json.type === 'select_master_agent'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>

                <v-select :options="masterAgent" item-text="name" item-value="id"
                  v-model="formEdit.parent_id[items.key]" @input="changeSelected(items.key)"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_airlanes'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterAirlanes" item-text="name" item-value="id" @input="changeSelected(items.key)"
                  v-model="formEdit.parent_id[items.key]" :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_currency'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>

                <v-select :options="masterCurrency" item-text="name" item-value="id" @input="changeSelected(items.key)"
                  v-model="formEdit.parent_id[items.key]" :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_depo'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>

                <v-select :options="masterDepo" item-text="name" item-value="id" @input="changeSelected(items.key)"
                  v-model="formEdit.parent_id[items.key]" :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_driver'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>

                <v-select :options="masterDriver" item-text="name" item-value="id" @input="changeSelected(items.key)"
                  v-model="formEdit.parent_id[items.key]" :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_item'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>

                <v-select :options="masterItem" item-text="name" item-value="id" @input="changeSelected(items.key)"
                  v-model="formEdit.parent_id[items.key]" :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_port'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>

                <v-select :options="masterPort" item-text="name" item-value="id" @input="changeSelected(items.key)"
                  v-model="formEdit.parent_id[items.key]" :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_reason'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>

                <v-select :options="masterReason" item-text="name" item-value="id" @input="changeSelected(items.key)"
                  v-model="formEdit.parent_id[items.key]" :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_shipping'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>

                <v-select :options="masterShipping" item-text="name" item-value="id" @input="changeSelected(items.key)"
                  v-model="formEdit.parent_id[items.key]" :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_term'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>

                <v-select :options="masterTerm" item-text="name" item-value="id" @input="changeSelected(items.key)"
                  v-model="formEdit.parent_id[items.key]" :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_truck'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>
                <v-select :options="masterTruck" item-text="name" item-value="id" @input="changeSelected(items.key)"
                  v-model="formEdit.parent_id[items.key]" :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_address'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>

                <v-select :options="masterAddress" item-text="name" item-value="id" @input="changeSelected(items.key)"
                  v-model="formEdit.parent_id[items.key]" :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_authorized'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>

                <v-select :options="masterAuthorized" item-text="name" item-value="id"
                  @input="changeSelected(items.key)" v-model="formEdit.parent_id[items.key]"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_assignment'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>

                <v-select :options="masterAssignment" item-text="name" item-value="id"
                  @input="changeSelected(items.key)" v-model="formEdit.parent_id[items.key]"
                  :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_ppjk'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>

                <v-select :options="masterPPJK" item-text="name" item-value="id" @input="changeSelected(items.key)"
                  v-model="formEdit.parent_id[items.key]" :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_tax'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>

                <v-select :options="masterTax" item-text="name" item-value="id" @input="changeSelected(items.key)"
                  v-model="formEdit.parent_id[items.key]" :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
              <div v-else-if="json.type === 'select_master_vendor'">
                <label class="mt-2">{{ items.title }}
                  <span style="color: red" v-if="json.required === '1'">*</span>
                </label>

                <v-select :options="masterVendor" item-text="name" item-value="id" @input="changeSelected(items.key)"
                  v-model="formEdit.parent_id[items.key]" :reduce="(option) => option.id" label="name">
                </v-select>
              </div>
            </div>
          </div>
          <hr style="border-top: 1px solid #e6e6e6" />
        </div>
        <div slot="modal-footer" class="w-100">
          <p class="float-left"></p>
          <input type="submit" class="btn btn-primary mr-1 float-right" value="Update" />
          <input @click="hideModalEdit" type="button" class="btn btn-secondary mr-1 float-right" value="Close" />
        </div>
      </form>
    </b-modal>
    <!-- end Modal Edit -->

    <!-- Modal Sub Created -->

    <!-- end Modal Created -->
  </div>
</template>
<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'

import DetailRow from './JobOrderSubRow'
import { authMethods } from '@state/helpers'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import Vue from 'vue'
import Swal from 'sweetalert2'

var url = '/job-order'

// const params = new Proxy(new URLSearchParams(window.location.search), {
//     get: (searchParams, prop) => searchParams.get(prop),
//   });
//   localStorage.setItem('flexmodActive', params.id_flexmod)

// for (
//   let i = 0;
//   i < JSON.parse(localStorage.getItem('userPermissions')).length;
//   i++
// ) {
//   if (
//     JSON.parse(localStorage.getItem('userPermissions'))[i] ===
//     'CRM_ADMIN_ENABLE'
//   ) {
//     url = '/job-order?admin=true'

//     if(localStorage.getItem('flexmodActive') !== undefined) {
//       url = '/job-order?admin=true&id_flexmod=' + localStorage.getItem('flexmodActive')
//     } else {
//       const params = new Proxy(new URLSearchParams(window.location.search), {
//         get: (searchParams, prop) => searchParams.get(prop),
//       });

//       url = '/job-order?admin=true&id_flexmod=' + params.id_flexmod

//       localStorage.setItem('flexmodActive', params.id_flexmod)
//     }

//   } else {
//     if(localStorage.getItem('flexmodActive') !== undefined) {
//       url = '/job-order?id_flexmod=' + localStorage.getItem('flexmodActive')
//     } else {
//       const params = new Proxy(new URLSearchParams(window.location.search), {
//         get: (searchParams, prop) => searchParams.get(prop),
//       });

//       url = '/job-order?id_flexmod=' + params.id_flexmod

//       localStorage.setItem('flexmodActive', params.id_flexmod)
//     }
//   }
// }
Vue.component('my-detail-row', DetailRow)

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    DatePicker,
  },
  props: {
    showActions: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      master: [],
      moreParams: {},
      filterText: '',
      deleteData: {},
      isLoadingTable: false,
      httpOptions: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      },
      apiUrl: this.$store.state.rootApi + url,
      lengthMenu: [10, 25, 50, 100],
      perPage: 10,
      fields: [
        {
          name: 'no',
          title: '#',
        },
        {
          name: 'no_joborder',
          title: 'No',
          sortField: 'id',
        },
        {
          name: 'id_number',
          title: 'Job No.',
        },
        {
          name: 'name_customer',
          title: 'Customer',
        },
        {
          name: '-',
          title: 'BL Number',
        },
        {
          name: '-',
          title: 'DO Number',
        },
        {
          name: '-',
          title: 'Suffing',
        },
        {
          name: 'name_sales',
          title: 'Marketing',
        },
        {
          name: 'name_created',
          title: 'Created By',
        },
        {
          name: 'status',
          title: 'Status',
        },
        {
          name: 'info',
          title: 'Info',
        },
        {
          name: 'action',
          title: 'Action',
        },
      ],
      sortOrder: [
        {
          field: 'id',
          direction: 'desc',
        },
      ],
      css: {
        pagination: {
          activeClass: 'btn-primary',
          pageClass: ' btn-sm btn-rounded',
          linkClass: ' btn-sm',
          icons: {
            prev: 'fas fa-arrow-left',
            next: 'fas fa-arrow-right',
          },
        },
      },
      momentFormatStartDateData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.startDateData).format('YYYY/MM/DD') : ''
        },
      },
      momentFormatEndDateData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.endDateData).format('YYYY/MM/DD') : ''
        },
      },
      year: null,
      createdBy: null,
      statusData: 'All',
      startDateData: null,
      endDateData: null,
      branchData: [],
      marketingData: [],
      rangeData: 'customData',
      topCustomerData: 'Select Number',
      isJobOrderNo: true,
      compareData: false,
      isManualTopCustData: false,
      monthData: '01',
      yearData: null,
      formatYearData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.yearData).format('YYYY') : ''
        },
      },
      isLoading: false,
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      jobfileTypeData: 'All',
      customerData: [],
      masterAgent: [],
      masterAirlanes: [],
      masterCurrency: [],
      masterDepo: [],
      masterDriver: [],
      masterItem: [],
      masterPort: [],
      masterReason: [],
      masterShipping: [],
      masterTerm: [],
      masterTruck: [],
      masterAddress: [],
      masterAuthorized: [],
      masterAssignment: [],
      masterPPJK: [],
      masterTax: [],
      masterBranch: [],
      masterVendor: [],
      value: '',
      selectBranch: [],
      selectCustomer: [],
      selectSales: [],
      selected: {
        item: [],
      },
      customer: '',
      sales: '',
      etd: '',
      eta: '',
      radioSelected: '',
      checkboxSelected: '',
      text: '',
      idJobOrder: '',
      autoJobOrderNo: '',
      form: {
        parent_id: [],
      },
      formEdit: {
        parent_id: [],
      },
      getEdit: {
        parent_id: [],
      },

      JobOrderCreated: {
        Branch: '',
        customer: '',
        sales: '',
        etd: '',
      },
      JobOrderEdit: {
        Branch: '',
        customer: '',
        sales: '',
      },
      checkedRequired: null,
      optionSelect: {
        selected: [],
      },
      branchJobOrderDefaultFlexmod: null,
      createdJobOrder: '',
      createdSubJobOrder: '',
      TitleModal: ''
    }
  },
  computed: {
    ...mapState('jobOrder', ['createdField']),
    state() {
      return Boolean(this.checkedRequired)
    },
  },
  mounted() {
    console.log(this.$route.query.id_flexmod, 'THIS ID FLEXMOD')

    for (
      let i = 0;
      i < JSON.parse(localStorage.getItem('userPermissions')).length;
      i++
    ) {
      if (
        JSON.parse(localStorage.getItem('userPermissions'))[i] ===
        'CRM_ADMIN_ENABLE'
      ) {
        this.apiUrl = this.$store.state.rootApi + '/job-order?admin=true'

        this.apiUrl = this.$store.state.rootApi + '/job-order?admin=true&id_flexmod=' + this.$route.query.id_flexmod

        localStorage.setItem('flexmodActive', this.$route.query.id_flexmod)



      } else {
        this.apiUrl = this.$store.state.rootApi + '/job-order?id_flexmod=' + this.$route.query.id_flexmod

        localStorage.setItem('flexmodActive', this.$route.query.id_flexmod)
      }
    }
    // const params = new Proxy(new URLSearchParams(window.location.search), {
    //   get: (searchParams, prop) => searchParams.get(prop),
    // });

    // localStorage.setItem('flexmodActive', params.id_flexmod)

    localStorage.setItem('tabIndexActiveJobOrder', 0)
    this.getCreatedField(this.$route.query.id_flexmod)
    this.listMasterBranch()
    this.branchFlexmod()
  },
  methods: {
    ...authMethods,

    errorAlertValidation: function (message = 'Terjadi Kesalahan') {
      this.$swal({
        title: message,
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
    },
    ...mapActions('jobOrder', [
      'getCreatedField',
      'getListMaster',
      'getListCustomer',
      'getListSales',
      'storeJobOrder',
      'updateJobOrder',
      'deleteJobOrder',
      'branchJobOrderDefault'
    ]),

    branchFlexmod() {
      try {
        this.branchJobOrderDefault(this.$route.query.id_flexmod)
          .then((res) => {
            this.branchJobOrderDefaultFlexmod = res
            localStorage.setItem('default_branch_flexmod', this.branchJobOrderDefaultFlexmod.id_branch_flexmod)
            console.log(this.branchJobOrderDefaultFlexmod.id_branch_flexmod, 'branch flexmod')

          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)

      }
    },

    listMasterAgent() {
      try {
        this.getListMaster('select_master_agent')
          .then((res) => {
            this.masterAgent = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterAirlanes() {
      try {
        this.getListMaster('select_master_airlanes')
          .then((res) => {
            this.masterAirlanes = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterCurrency() {
      try {
        this.getListMaster('select_master_currency')
          .then((res) => {
            this.masterCurrency = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterDepo() {
      try {
        this.getListMaster('select_master_depo')
          .then((res) => {
            this.masterDepo = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterDriver() {
      try {
        this.getListMaster('select_master_driver')
          .then((res) => {
            this.masterDriver = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterItem() {
      try {
        this.getListMaster('select_master_item')
          .then((res) => {
            this.masterItem = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterPort() {
      try {
        this.getListMaster('select_master_port')
          .then((res) => {
            this.masterPort = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterReason() {
      try {
        this.getListMaster('select_master_reason')
          .then((res) => {
            this.masterReason = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterShipping() {
      try {
        this.getListMaster('select_master_shipping')
          .then((res) => {
            this.masterShipping = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterTerm() {
      try {
        this.getListMaster('select_master_term')
          .then((res) => {
            this.masterTerm = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterTruck() {
      try {
        this.getListMaster('select_master_truck')
          .then((res) => {
            this.masterTruck = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterAddress() {
      try {
        this.getListMaster('select_master_address')
          .then((res) => {
            this.masterAddress = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterAuthorized() {
      try {
        this.getListMaster('select_master_authorized')
          .then((res) => {
            this.masterAuthorized = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterAssignment() {
      try {
        this.getListMaster('select_master_assignment')
          .then((res) => {
            this.masterAssignment = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterPPJK() {
      try {
        this.getListMaster('select_master_ppjk')
          .then((res) => {
            this.masterPPJK = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterTax() {
      try {
        this.getListMaster('select_master_tax')
          .then((res) => {
            this.masterTax = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterBranch() {
      try {
        this.getListMaster('select_master_branch?id_flexmod=' + this.$route.query.id_flexmod)
          .then((res) => {
            this.masterBranch = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterVendor() {
      try {
        this.getListMaster('select_master_vendor')
          .then((res) => {
            this.masterVendor = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    getMarketingByBranchData() {
      try {
        let getCustomer = this.masterBranch.find(
          (x) => x.id === this.JobOrderCreated.Branch
        )
        this.JobOrderEdit.customer = ''
        this.JobOrderEdit.sales = ''
        let strBranch = ''
        strBranch += 'id_branch=' + getCustomer.id
        // alert(strBranch)
        this.getListCustomer(strBranch)
          .then((res) => {
            this.selectCustomer = res
          })
          .catch((err) => {
            console.log(err)
          })

        this.getListSales(strBranch)
          .then((res) => {
            this.selectSales = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    getMarketingData() {
      this.getMarketingByBranchData()
    },

    getMarketingByBranchDataEdit() {
      try {
        let getCustomer = this.masterBranch.find(
          (x) => x.id === this.JobOrderEdit.Branch
        )
        this.JobOrderEdit.customer = ''
        this.JobOrderEdit.sales = ''
        let strBranch = ''
        strBranch += 'id_branch=' + getCustomer.id

        // alert(strBranch)
        this.getListCustomer(strBranch)
          .then((res) => {
            this.selectCustomer = res
          })
          .catch((err) => {
            console.log(err)
          })

        this.getListSales(strBranch)
          .then((res) => {
            this.selectSales = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    getMarketingDataEdit() {
      this.getMarketingByBranchDataEdit()
    },
    getAllData() {
      let myForm = document.getElementById('frm')
      let formData = new FormData(myForm)
      const data = {
        branch: this.JobOrderCreated.Branch,
        customer: this.JobOrderCreated.customer,
        sales: this.JobOrderCreated.sales,
        etd: this.JobOrderCreated.etd,
        id_flexmod: localStorage.getItem('flexmodActive')
      }

      for (let [key, val] of formData.entries()) {
        Object.assign(data, { [key]: val })
      }

      this.createdField.forEach((item) => {
        if (item.is_type_group === 'radio') {
          Object.assign(data, {
            [item.key]:
              this.form.parent_id[item.key] === undefined ||
                this.form.parent_id[item.key] === null
                ? ''
                : this.form.parent_id[item.key],
          })
        }
        if (item.is_type_group === 'form_input') {
          item.company_setting_job_order_item.forEach((el) => {
            if (el.type !== 'number' && el.type !== 'date') {
              Object.assign(data, {
                [el.key]:
                  this.form.parent_id[el.key] === undefined
                    ? ''
                    : this.form.parent_id[el.key],
              })
            }
          })
        }
      })

      console.log(data)

      let headers = {
        'Content-Type': 'application/json',
      }

      this.storeJobOrder({
        payload: data,
        headers: headers,
      })
        .then((res) => {
          if (res.data !== 0) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              },
            })

            if (res.code >= 200 && res.code <= 300) {
              if (res.data.s === true) {
                Toast.fire({
                  icon: 'success',
                  title: res.message,
                })

                this.hideModalCreated()
                this.$refs.vuetable.refresh()
                this.form.parent_id = ''
                this.$router.push({
                  name: 'JobOrder Details',
                  params: {
                    id: res.id,
                  },
                })
              }
            } else if (res.code === 401) {
              Swal.fire({
                title: 'Success with some Error',
                html:
                  'Data has been save in oaktree database with <span style="color: red;font-weight: bold;">some error in accurate online</span><br/><br/><span style="font-size: 11px;font-style: italic;">"' + res.message + '"</span>',
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok',
              })
                .then((result) => {
                  if (result.isConfirmed) {
                    this.$refs.vuetable.refresh()
                    this.form.parent_id = ''
                    this.$router.push({
                      name: 'JobOrder Details',
                      params: {
                        id: res.id,
                      },
                    })
                  }
                })
              this.hideModalCreated()
            } else {

              Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok',
              })
              this.hideModalCreated()
            }
          } else {
            Swal.fire({
              title: "Autonumber Can't be Null!",
              text: 'Please check Master Autonumber Quotation!',
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok',
            })
          }
        })
        .catch((err) => {
          if (err.response.status === 422) {
            let title = []
            for (let field of Object.keys(err.response.data.message)) {
              title.push(field)
            }
            Swal.fire({
              title: 'Error',
              text: 'Please insert field ' + title.join(),
              icon: 'error',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok',
            })
          } else {
            console.log(err)
          }
        })
    },
    getUpdate() {
      let myForm = document.getElementById('frm-edit')
      let formData = new FormData(myForm)
      const data = {
        branch: this.JobOrderEdit.Branch,
        customer: this.JobOrderEdit.customer,
        sales: this.JobOrderEdit.sales,
      }
      // need to convert it before using not with XMLHttpRequest
      for (let [key, val] of formData.entries()) {
        Object.assign(data, { [key]: val })
      }

      this.createdField.forEach((item) => {
        if (item.is_type_group === 'form_input') {
          item.company_setting_job_order_item.forEach((el) => {
            if (el.type !== 'number' && el.type !== 'date') {
              Object.assign(data, {
                [el.key]:
                  this.formEdit.parent_id[el.key] === undefined
                    ? ''
                    : this.formEdit.parent_id[el.key],
              })
            }
          })
        }
      })
      // console.log(data, 'data form')
      let headers = {
        'Content-Type': 'application/json',
      }

      this.updateJobOrder({
        payload: data,
        headers: headers,
      })
        .then((res) => {
          if (res.data !== 0) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              },
            })

            if (res.code >= 200 && res.code <= 300) {
              if (res.data.s === true) {
                Toast.fire({
                  icon: 'success',
                  title: res.message,
                })

                this.hideModalEdit()
                this.$refs.vuetable.refresh()
              } else {
                Swal.fire({
                  title: 'Error',
                  text: res.data.d,
                  icon: 'error',
                  confirmButtonColor: 'rgb(221, 107, 85)',
                  confirmButtonText: 'Ok',
                })
                this.hideModalEdit()
              }
            } else {
              Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok',
              })
              this.hideModalEdit()
            }
          } else {
            Swal.fire({
              title: "Autonumber Can't be Null!",
              text: 'Please check Master Autonumber Quotation!',
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok',
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    setJson(payload) {
      this.json = JSON.parse(payload)
    },
    firstCapital(payload) {
      return payload[0].toUpperCase() + payload.slice(1)
    },

    doFilter() {
      this.onFilterSet(this.filterText)
    },
    doFilterMultipleKeywords() {
      this.onFilterSetMultipleKeywords()
    },
    /**
     * Table Length Change
     *
     * @return  {[type]}  [return description]
     */
    onChangeTableLength() {
      // this.$refs.vuetable.reload()
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    /**
     * Pagination page change
     */
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    /**
     * Pagination info and pagination show
     */
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    /**
     * [onFilterSet description]
     *
     * @param   {[type]}  filterText  [filterText description]
     *
     * @return  {[type]}              [return description]
     */
    onFilterSet(filterText) {
      this.moreParams = {
        search: filterText,
      }
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterSetMultipleKeywords(filterText) {
      let tempStartDateData = ''
      let tempEndDateData = ''

      if (this.rangeData === 'customData') {
        tempStartDateData = this.startDateData
        tempEndDateData = this.endDateData
      } else if (this.rangeData === 'monthlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth(),
            2
          )
            .toISOString()
            .split('T')[0]
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth() + 1,
            1
          )
            .toISOString()
            .split('T')[0]
        }
      } else if (this.rangeData === 'yearlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() + '/01/01'
          )
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() +
            '/12/' +
            new Date(
              new Date(this.yearData.toString()).getFullYear(),
              12,
              0
            ).getDate()
          )
        }
      }

      let strMarketing = []
      this.marketingData.forEach((data, idx) => {
        strMarketing.push(data.id_user)
      })

      let strCustomer = []
      this.customerData.forEach((data, idx) => {
        strCustomer.push(data.id)
      })

      if (this.jobfileType === '') {
        this.errorAlertValidation('Jobfile Type is required!')
      } else if (this.statusData === '') {
        this.errorAlertValidation('Status is required!')
      } else if (this.rangeData === '') {
        this.errorAlertValidation('Range is required!')
      } else if (this.rangeData !== '') {
        if (this.rangeData === 'customData') {
          if (this.startDateData === '' || this.startDateData == null) {
            this.errorAlertValidation('Start Date is required!')
          } else if (this.endDateData2 === '' || this.endDateData == null) {
            this.errorAlertValidation('End Date is required!')
          } else {
            this.$nextTick(() => this.$refs.vuetable.refresh())
          }
        } else if (this.rangeData === 'monthlyData') {
          if (this.monthData === '' || this.monthData == null) {
            this.errorAlertValidation('Month is required!')
          } else if (this.yearData === '' || this.yearData == null) {
            this.errorAlertValidation('Year is required!')
          } else {
            this.$nextTick(() => this.$refs.vuetable.refresh())
          }
        } else if (this.rangeData === 'yearlyData') {
          if (this.yearData === '' || this.yearData == null) {
            this.errorAlertValidation('Year is required!')
          } else {
            this.$nextTick(() => this.$refs.vuetable.refresh())
          }
        }
      }

      this.moreParams = {
        branch: localStorage.getItem('flexmodBranchActive'),
        customer: strCustomer,
        jobfileType: this.jobfileTypeData,
        startDate: tempStartDateData,
        endDate: tempEndDateData,
        sales: strMarketing,
        status: this.statusData,
        perPage: 10,
        filter: true,
      }
      // console.log(this.moreParams, 'THIS PARAMSSS')
      // console.log(filterText)
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    /**
     * Show Table Loading
     *
     * @return  {[type]}  [return description]
     */
    loadingTable() {
      this.isLoadingTable = true
    },

    /**
     * Hide Loading if Success
     *
     * @return  {[type]}  [return description]
     */
    loadTableSuccess() {
      this.isLoadingTable = false
    },
    /**
     * Hide Loading if Failed
     *
     * @return  {[type]}  [return description]
     */
    loadTableFailed(error) {
      console.log(error)

      this.isLoadingTable = false
      this.$swal({
        title: 'Terjadi Kesalahan',
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
      // }
    },

    formatDate(type) {
      let tempStartDateData = ''
      let tempEndDateData = ''

      // console.log(this.monthData1.toString())
      // console.log(new Date(this.yearData2.toString()).getFullYear())

      if (this.rangeData === 'customData') {
        tempStartDateData = this.startDateData
        tempEndDateData = this.endDateData
      } else if (this.rangeData === 'monthlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth(),
            2
          )
            .toISOString()
            .split('T')[0]
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth() + 1,
            1
          )
            .toISOString()
            .split('T')[0]
        }
      } else if (this.rangeData === 'yearlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() + '/01/01'
          )
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() +
            '/12/' +
            new Date(
              new Date(this.yearData.toString()).getFullYear(),
              12,
              0
            ).getDate()
          )
        }
      }

      if (tempStartDateData === null || tempEndDateData === null) {
        return ''
      }
      return (
        this.monthNames[tempStartDateData.getMonth()] +
        ' ' +
        tempStartDateData.getFullYear() +
        ' - ' +
        this.monthNames[tempEndDateData.getMonth()] +
        ' ' +
        tempEndDateData.getFullYear()
      )
    },

    getApiMaster() {
      this.createdField.forEach((item) => {
        item.company_setting_job_order_item.forEach((el) => {
          const json = JSON.parse(el.meta)
          if (json.type === 'select_master_agent') {
            this.listMasterAgent()
          } else if (json.type === 'select_master_airlanes') {
            this.listMasterAirlanes()
          } else if (json.type === 'select_master_currency') {
            this.listMasterCurrency()
          } else if (json.type === 'select_master_depo') {
            this.listMasterDepo()
          } else if (json.type === 'select_master_driver') {
            this.listMasterDriver()
          } else if (json.type === 'select_master_item') {
            this.listMasterItem()
          } else if (json.type === 'select_master_port') {
            this.listMasterPort()
          } else if (json.type === 'select_master_reason') {
            this.listMasterReason()
          } else if (json.type === 'select_master_shipping') {
            this.listMasterShipping()
          } else if (json.type === 'select_master_term') {
            this.listMasterTerm()
          } else if (json.type === 'select_master_truck') {
            this.listMasterTruck()
          } else if (json.type === 'select_master_address') {
            this.listMasterAddress()
          } else if (json.type === 'select_master_authorized') {
            this.listMasterAuthorized()
          } else if (json.type === 'select_master_assignment') {
            this.listMasterAssignment()
          } else if (json.type === 'select_master_ppjk') {
            this.listMasterPPJK()
          } else if (json.type === 'select_master_tax') {
            this.listMasterTax()
          } else if (json.type === 'select_master_vendor') {
            this.listMasterVendor()
          }
        })
      })
    },

    openModalCreated(props) {
      this.$bvModal.show('my-modal')
      this.selectBranch = ''
      this.autoJobOrderNo = ''
      this.isJobOrderNo = true
      this.isManualJobOrderNo = false
      this.radioSelected = ''
      this.JobOrderCreated.customer = ''
      this.JobOrderCreated.sales = ''
      if (props === null) {
        this.idJobOrder = ''
        this.JobOrderEdit.Branch = parseInt(localStorage.getItem('default_branch'))
        this.JobOrderCreated.Branch = parseInt(localStorage.getItem('default_branch'))
        this.createdJobOrder = true
        this.createdSubJobOrder = false
        this.TitleModal = 'Add Job Order'
        this.getMarketingDataEdit()

        console.log(localStorage.getItem('default_branch'))
      } else {
        this.JobOrderEdit.Branch = props.rowData.id_branch
        this.JobOrderCreated.Branch = props.rowData.id_branch
        this.idJobOrder = props.rowData.id
        this.createdJobOrder = false
        this.createdSubJobOrder = true
        this.TitleModal = 'Add Child Job Order'
        this.getMarketingDataEdit()
      }
      this.getApiMaster()
    },
    hideModalCreated() {
      this.$bvModal.hide('my-modal')
    },

    openModalEdit(data) {
      this.getApiMaster()

      this.checkboxSelected = ''
      this.formEdit.parent_id = []
      this.formEdit.selected = []
      this.$bvModal.show('my-modal-edit')
      this.JobOrderEdit.Branch = data.rowData.id_branch
      this.getMarketingDataEdit()
      this.JobOrderEdit.customer = data.rowData.meta_field.customer
      this.JobOrderEdit.sales = data.rowData.meta_field.sales
      this.etd = data.rowData.meta_field.etd
      this.eta = data.rowData.meta_field.eta
      this.idJobOrder = data.rowData.id
      this.autoJobOrderNo = data.rowData.id_number
      let checkBox = []
      let radioBox = ''
      Object.entries(data.rowData.meta_field).forEach((entry) => {
        const [key, value] = entry
        // this.formEdit.parent_id[options]
        this.formEdit.parent_id[key] = value

        this.createdField.forEach((item) => {
          if (item.is_type_group === 'checklist') {
            item.company_setting_job_order_item.forEach((el) => {
              const meta = JSON.parse(el.meta)
              if (meta.type === 'checkbox') {
                if (value === el.key) {
                  checkBox.push(value)
                }
              }
            })
          } else if (item.is_type_group === 'radio') {
            item.company_setting_job_order_item.forEach((el) => {
              const meta = JSON.parse(el.meta)
              if (meta.type === 'radio') {
                if (value === el.key) {
                  radioBox = value
                }
              }
            })
          } else {
            item.company_setting_job_order_item.forEach((el) => {
              if (key === el.key) {
              }
            })
          }
        })
      })
      // console.log(this.getEdit.parent_id)
      this.checkboxSelected = checkBox
      this.radioSelected = radioBox
    },

    changeSelected(options) {
      let opt = options
      let num = this.formEdit.parent_id[options]
      this.$delete(this.formEdit.parent_id, options)
      this.formEdit.parent_id[opt] = num
    },

    showConfirmDelete(id) {
      this.deleteData.id = id
      let self = this
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 107, 85)',
        cancelButtonColor: '#C1C1C1',
        reverseButtons: true,
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          self.onDelete(id)
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
        }
      })
    },

    showConfirmDeleteMessage(id) {
      Swal.fire({
        title: "Warning",
        text: 'You will not delete this data, your have Sub Job Order!',
        icon: 'warning',
        confirmButtonColor: 'rgb(221, 107, 85)',
        confirmButtonText: 'Ok',
      })
    },
    /**
     * Delete Brand
     *
     * @return  {[type]}  [return description]
     */
    onDelete(id) {
      this.deleteJobOrder(id)
        .then((res) => {
          this.successAlert()
          this.$refs.vuetable.refresh()
        })
        .catch((err) => {
          this.errorAlert()
          console.log(err.response.message)
        })
    },
    hideModalEdit() {
      this.$bvModal.hide('my-modal-edit')
    },

    openModalSubJobCreated() {
      this.$bvModal.show('my-modal-subJob')
    },
    hideModalSubJobCreated() {
      this.$bvModal.hide('my-modal-subJob')
    },

    changeJobOrderNo() {
      this.isManualJobOrderNo = !this.isManualJobOrderNo
      this.isJobOrderNo = !this.isJobOrderNo
    },

    onCellClicked(data, field, event) {
      console.log('cellClicked: ', field.id)
      this.$refs.vuetable.toggleDetailRow(data.id)
    },

    onAction(action, rowData, rowIndex) {
      if (action === 'details') {
        this.$router.push({
          name: 'JobOrder Details',
          params: {
            id: rowData.id,
          },
        })
      }
    },

    getCustomer(value) {
      let parse = JSON.parse(value)
      return parse.customer
    },
  },
}
</script>
<style>
.created-field svg {
  color: #51c1ad;
}

.radio-no-between {
  justify-content: flex-start !important;
}

.form-control[readonly] {
  background-color: #ced4da8c;
  opacity: 1;
}
</style>

