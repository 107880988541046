  <template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <ul class="nav nav-tabs pt-3" style="border: 0">
          <li class="active"
            ><a href="#" class="btn btn-sm btn-head text-white"
              >Job Order Information</a
            ></li
          >
        </ul>
        <div class="card new-card">
          <div class="card-body">
            <!-- <div class="row">

            </div> -->
            <JobOrderList :show-action="true"></JobOrderList>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

  <script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
// import PageHeader from '@components/page-header'
import JobOrderList from '@components/JobOrder/JobOrderList.vue'

export default {
  page: {
    title: 'Job Order',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    JobOrderList,
    Layout,
    // PageHeader,
  },
  data() {
    return {
      title: 'Job Order',
    }
  },
}
</script>
